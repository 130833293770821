import axios from "axios";
import { useEffect, useState } from "react";
import "./DataFetch.css"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DataFetch = () => {
  const [error, setError] = useState(null);
  const [restaurants, setRestaurants] = useState([]);


  useEffect(() => {
    axios
      .get("https://strapi.wpplusone.com/api/articles?populate=*")
      .then(({ data }) => setRestaurants(data.data))
      .catch((error) => setError(error));
  }, []);

  
  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }

  return (
    <div className="App">
      <div class="container">
       <div class="blog-grid-row">
        {restaurants.map(({ id, attributes, }) => (
        <div class="blog-grid-item">
                <div class="grid-item-wrapper">
                   <div class="grid-item-container">
                           <div class="grid-image-top sputnik">
                               <span key={id}>
                                <img src={"https://strapi.wpplusone.com/"+attributes.FeatureImage.data.attributes.url} alt="subas" class="FeaturImage"> 
                                </img>
                                </span>
                           </div>
                           <div class="grid-item-content" key={id}>
                           <Link to={`/post/${id}`}>
                               <span class="item-title" key={id}>{attributes.Title}</span>
                               <span class="item-excerpt" key={id}>{attributes.Excert}</span> 
                               <button class="button-readmore" key={id} href="" role="button">Readmore</button>
                               </Link>
                           </div>
                    </div>
                </div>
        </div>
        ))}
    </div>
    </div>
    </div>
  );
        }             
export default DataFetch;